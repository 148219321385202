import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Navbar from "../components/navbar-nonhome"
import { css } from '@emotion/react'
import BackgroundImage from 'gatsby-background-image'
import Slider from "react-slick"
import { Helmet } from "react-helmet"
import {Trans, Link, I18nextContext, useTranslation} from 'gatsby-plugin-react-i18next';


const settings = {
  arrows: false,
    dots: true,
    /*centerMode: true,
    centerPadding: "5%",*/
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    autoplaySpeed: 8000,
    autoplay: false,
    slidesToScroll: 2,
  };

export default function Jobs({ data }) {
  const {t} = useTranslation();
  const context = React.useContext(I18nextContext);
  const taal = context.language
  if (taal === "nl") {var content = data.wp.jobpagina.jobpagina}
  if (taal === "fr") {content = data.wp.jobpagina.jobpagina.frans}
    return (
        <Layout>
<Helmet titleTemplate="%s | Asbest Verwijderen | Asbest Saneren | De Nr 1 Expert in België | Asbitech"
            htmlAttributes={{
              lang: `${taal}`,
            }}
            >
            <title>{t('Jobs')}</title>
            <meta name="description" content={t('Wil jij meewerken aan een asbestvrije samenleving? Ontdek hier onze vacatures!')} />
            </Helmet>
            <Navbar />
            <div  className="headerpadding" />
          <div className="mobile" css={css`max-width: 1300px; margin: auto auto; padding-bottom: 3rem;`}>
          <div css={css`font-size: 2.8rem; line-height: 3.2rem;font-weight: 500; color: var(--text-color); padding-top: 2rem; padding-bottom: 4rem;`}><Trans>Wil jij meewerken aan een asbestvrije samenleving?</Trans></div>
          <Slider className="jobsslider" {...settings}>
          {content.jobpagina.map(({ index, tekst, titel, afbeelding})=> {
              return (<div css={css`
                min-height: 500px;
                width: 300px;
                margin-bottom: 40px;
                padding: 1rem;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                align-content: flex-start;
            
            `}>
                  <div key={index} css={css`width: 100%; text-align: center; margin-bottom: 2rem;
                  `}>
                      <BackgroundImage
            fluid={afbeelding.localFile.childImageSharp.fluid}
            css={css`height: 250px; background-position: center center`}
            alt=""
          />
                  </div>
                  <h3>{titel}</h3>
                  {tekst}
              </div>
          )}
          )}

          </Slider>
          
          <div className="jobsgrid" css={css`flex-wrap: wrap;justify-content: space-between;`}>

          {content.jobpagina.map(({ index, tekst, titel, afbeelding})=> {
              return (<div className="jobswidget">
                  <div key={index} css={css`width: 100%;   text-align: center; margin-bottom: 2rem;
                  `}>
                      <BackgroundImage
            fluid={afbeelding.localFile.childImageSharp.fluid}
            css={css`width: 100%; height: 250px; background-position: center center`}
            alt=""
          />
                  </div>
                  <h3>{titel}</h3>
                  {tekst}
              </div>
          )}
          )}
          
          </div>
          </div>



        <div id="vacatures" css={css`width: 100%; background-color: var(--text-color);`}>
        <div className="mobile" css={css`max-width: 1000px; margin: auto auto; padding-bottom: 4rem; color: var(--background-color); padding-top: 3rem; padding-bottom: 2rem; `}>
        <h1><Trans>Vacatures</Trans></h1>

        {data.allWpJob.edges.map(({ index, node })=> {
            if (node.jobomschrijving.type == null)
                {var hideJobSpecs = "none"}
                else { var hideJobSpecs = "block"}
                if (node.jobomschrijving.type=== "arbeider" && taal === "nl") {var content = "arbeider"}
                if (node.jobomschrijving.type=== "arbeider" && taal === "fr") {var content = "travailleur"}
                if (node.jobomschrijving.type=== "bediende" && taal === "nl") {var content = "bediende"}
                if (node.jobomschrijving.type=== "bediende" && taal === "fr") {var content = "greffier"}
                if (node.jobomschrijving.duur=== "voltijds" && taal === "nl") {var duur = "voltijds"}
                if (node.jobomschrijving.duur=== "voltijds" && taal === "fr") {var duur = "à temps plein"}
                if (node.jobomschrijving.duur=== "deeltijds" && taal === "nl") {var duur = "deeltijds"}
                if (node.jobomschrijving.duur=== "deeltijds" && taal === "fr") {var duur = "à temps partiel"}
           return (
        <div key={index} className="vacaturecontainer">
            <div css={css`margin-bottom: 1rem; display: ${hideJobSpecs}`}><span css={css`margin-right: 0.5rem;`} className="nieuws">{content}</span><span className="project">{duur}</span></div>
            <h2 css={css`padding-bottom: 0.5rem;`}>{node.title}</h2>
            <Link className='tekstbutton-yellow' css={css`color: var(--background-color)`} to={`/jobs/${node.slug}`}><Trans>Lees Meer</Trans></Link>
        </div>
        )})}
        </div>

        </div>
        </Layout>

      )
  }


export const query = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
    wp {
        jobpagina {
          jobpagina {
            frans {
              jobpagina {
                tekst
                titel
                afbeelding {
                  localFile {
                    childImageSharp {
                      fluid(quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
            jobpagina {
              tekst
              titel
              afbeelding {
                localFile {
                  childImageSharp {
                    fluid(quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    allWpJob (
      filter: {language: {locale: {eq: $language}}}
      sort: {fields: date, order: ASC}) {
        edges {
          node{
          title
          uri
          slug
          jobomschrijving {
            duur
            type
          }
        }
        }
      }
  }
`  